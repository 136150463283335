@import '~izitoast/dist/css/iziToast.min.css';
@import "~font-awesome/css/font-awesome.min.css";
@import "styles/variables";
@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../src/Assets/Images/footer_web.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  overflow: hidden;
}

* {
  font-family: "Roboto" !important;
  font-size: 15px;
}


.container__preview_doc {
  font-family: "Noto Sans" !important;
  line-height: 19px !important;
  margin-bottom: 0;
  font-size: 17px;

  * {
    @extend .container__preview_doc;
  }
}

@media (max-width: 600px) {
  body {
    background-image: url("../src/Assets/Images/footer_mobile.svg");
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
