@import "../../styles/variables";

.document-container {
  box-shadow: 0 0.25em 1.25em 0 #e8f1fc;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;

  &:hover {
    box-shadow: 0 .5rem 1rem #e8f1fc !important;
  }

  &-list {
    &:hover {
      .title {
        color: $primary;
      }
    }
  }

  &-description {
    color: rgba(38, 50, 82, .55);
  }

  .wrap {
    width: 100%;
    max-height: 100px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
  }


  .btn-outline-primary {
    color: $primary;
    border-color: $primary;

    &:hover {
      color: white;
      background: $primary;
    }
  }

  &-button {
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}

.primary-bg {
  background: $primary;
}

.category {
  &-title {
    font-size: 2em;
    font-family: "Playfair Display", Georgia, Times, "Times New Roman", serif;
  }

  &-description {
    color: rgba(255, 255, 255, .75);
  }
}

.sidebar {
  &-links {
    font-size: 18.4px;
    color: rgba(38, 50, 82, 0.75);
  }

  &-sticky {
    position: sticky;
    top: 7.25em;
    left: 0;
  }
}

.search-input {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px) !important;
  padding: .375rem .75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: .25rem !important;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.active {
  color: $primary !important;
}


.container-signature {
  border: 1px solid #d6d6d6;
  border-bottom: 1px solid black;
  min-width: 250px;
  width: max-content;
  height: 120px;
  border-style: dotted;
  border-bottom-style: solid;
  display: flex;
  align-items: flex-end;
  padding-left: 10px;
  margin-bottom: 10px;
}
