@import "./styles/variables";
@import "./styles/fonts";
@import "./styles/helpers";

.s {
  border-top: 1px solid rgba(212, 212, 212, 0.66);
  height: 1px;

  &-25 {

    width: 25%;
  }

  &-50 {
    width: 50%;
  }

  &-75 {
    width: 75%;
  }

  &-100 {
    width: 100%;
  }

}


#br-30 {
  border-radius: 40px !important;
}

.loading-spinner {
  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: rgba(191, 191, 191, 0.45);
}

.css-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #516176;
}

.btn-float {
  padding: 0;
  position: absolute;
  top: -1px;

  &-left {
    @extend .btn-float;
    left: -12px;
  }

  &-right {
    @extend .btn-float;
    right: -16px;
    top:-5px
  }
}


.form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #516176;
  background: rgb(238, 238, 238) !important;
  border-radius: 3px !important;
  border: 1px solid rgba(196, 196, 196, 0.14) !important;
}


.css-custom-select .css-yk16xz-control {
  background: rgba(196, 196, 196, 0.14) !important;
}

.select-is-invalid .css-yk16xz-control {
  border: 2px solid hsla(0, 90%, 70%, 0.38) !important;
  box-shadow: 0 0 0 1px hsla(0, 90%, 70%, 0.41);
  background: rgba(196, 196, 196, 0.14) !important;

}

.select-is-valid .css-yk16xz-control {
  border-color: rgba(196, 196, 196, 0.14) !important;
  background: rgba(196, 196, 196, 0.14) !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.vh-90 {
  height: 90vh;
}

.vh-50 {
  height: 50vh;
}


.navbar {
  background: white !important;
  width: 100%;
  z-index: 100;
  position: sticky !important;
  position: -webkit-sticky !important;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 8px;

}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}

.react-autosuggest__container {
  position: relative;
  color: black;
  width: 100%;
}

.react-time-picker__wrapper {
  border: 0 !important;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.sigCanvas {
  height: 40vh;
  width: 100%;
  border: 1px solid #9b9b9b;
}

.custom-position {
  .MuiPagination-root {
    display: flex;
    justify-content: center;
  }
}


.table-field {
  thead {
    background: #bfbfbf !important;
  }

  th, td {
    padding-top: 25px;
    padding-bottom: 2px;
    border: 1px solid;
  }

}

.pdf_file_preview {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999;
  background: white;
}

.btn {
  font-size: 15px !important;
}

.sidebar-links {
  font-size: 19.4px !important;
}

.font-default {
  font-size: 15px !important;
}
