.navbar {
  &-menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1024;
    background: rgba(255, 255, 255, 0.92);
    backdrop-filter: blur(27px);
    box-shadow: 1px 0 10px 1px rgba(0, 0, 0, 0.5);
    animation-name: expand;
    animation-duration: 0.2s;

    &-text {
      &:hover{
        color: #432996;
      }
      cursor: pointer;
      &-up {
        font-weight: bold;
        font-size: 33px;
        line-height: 39px;
      }

      &-down {
        font-size: 33px;
        line-height: 39px;
      }
    }
  }

  &-close-button{
    color: #432996;
    font-size: 23px;
    cursor: pointer;
  }
}

.input-search {
  height: 47px;
  font-size: 13px;
  &::placeholder {
    color: #1D3493;
    opacity: 0.62;
  }
}
