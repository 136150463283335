@import "variables";


.btn {
  border-radius: 30px !important;

  &-primary {
    border: 0;

    &:hover {
      border: 0;
    }
  }
}


.px-7 {
  padding-left: 90px;
  padding-right: 90px;
}

.text-gray {
  color: #ababab;
}

.w-30 {
  width: 30%;
}


.opacity-0 {
  opacity: 0;
}

.vh-80 {
  height: 80vh;
}

.vh-8 {
  height: 8vh;
}

.pointer {
  cursor: pointer;
}


.border-dark-light {
  border-color: #404040;
}

.vh-10 {
  height: 10vh;
}

.vh-90 {
  height: 90vh;
}

.scroll-smooth {
  scroll-behavior: smooth;
}


@media (max-width: 992px) {
  .vh-sm-80 {
    height: 78vh !important;
  }
}

@media (min-width: 992px) {
  .vh-lg-90 {
    height: 90vh !important;
  }
}

.h-sm {
  &-90 {
    @media (max-width: 767px) {
      height: 90% !important;
    }
  }
}


.br {
  &-none {
    border-radius: 0 !important;
  }

  &-17 {
    border-radius: 17px;
  }

  &-5 {
    border-radius: 5px;
  }

  &-30 {
    border-radius: 40px !important;
  }
}


.outline {
  &-none {
    outline: none !important;
  }
}


.p {
  &-m {
    &-0 {
      p {
        margin: 0 !important;
      }
    }
  }
}
