$custom-blue: #516176;
$gray-bg: #F7F7F7;

.section {
  font-family: Aller, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;

  &-name {
    @extend .section;

    &-active {
      @extend .section-name;
      color: $custom-blue;
      font-weight: bold;
    }
  }

  &-active-inputs {
    @extend .section-name;
    font-size: 22px;
    color: $custom-blue;
    font-weight: bold;
  }

  &-active-descriptions {
    @extend .section-name;
    font-size: 15px;
    color: $custom-blue;
    text-align: justify;

    &-overflow {
      max-height: 50vh;
      overflow-y: auto;
    }
  }

  &-inputs-label {
    @extend .section-name;
    font-size: 15px;
    color: $custom-blue;
  }

  &-mobile-steps {
    @extend .section;
    font-size: 15px;
  }

  &-container {
    height: 82vh;
    overflow-y: auto;
    padding: 50px 70px 0 70px;
    @media (max-width: 992px) {
      padding: 0;
      height: auto;
    }
  }

  &-title {
    @extend .section;
    font-weight: bold;
    font-size: 22px;
  }

  &-round-number {
    @extend .section;
    width: 20px;
    height: 20px;

    &-active {
      @extend .section-round-number;
      color: white;
      background: $custom-blue;
    }

    &-inactive {
      @extend .section-round-number;
      color: $custom-blue;
    }
  }

  &-underline {
    margin-left: 36px;
  }

  &-bg {
    background: $gray-bg;
    @media (max-width: 768px) {
      background: white;
    }
  }

  &-actions-container {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
  }

  &-size {
    margin-right: 90px;
    height: 90vh;
    @media (max-width: 768px) {
      height: auto;
      display: none;
    }

    &-footer {
      bottom: 0;
      right: 0;
      left: 0;
      position: absolute;
      width: 35%;
      @media (max-width: 992px) {
        width: 100% !important;
      }
    }

    &-inputs {
      width: 40%;
      @media (max-width: 768px) {
        width: 100% !important;
      }
    }
  }

  &-form {
    width: 75%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.align-right {
  right: 0;
}


