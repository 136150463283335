@font-face {
  font-family: 'Roboto';
  src: url('../Assets/Fonts/Roboto-Regular.woff2') format('woff2'),
  url('../Assets/Fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Times";
  src: url("//db.onlinewebfonts.com/t/c17fb8410ab11a6f235ddf6fe97e9303.eot");
  src: url("//db.onlinewebfonts.com/t/c17fb8410ab11a6f235ddf6fe97e9303.eot?#iefix") format("embedded-opentype"),
  url("//db.onlinewebfonts.com/t/c17fb8410ab11a6f235ddf6fe97e9303.woff2") format("woff2"),
  url("//db.onlinewebfonts.com/t/c17fb8410ab11a6f235ddf6fe97e9303.woff") format("woff"),
  url("//db.onlinewebfonts.com/t/c17fb8410ab11a6f235ddf6fe97e9303.ttf") format("truetype"),
  url("//db.onlinewebfonts.com/t/c17fb8410ab11a6f235ddf6fe97e9303.svg#Times") format("svg");
}

@font-face {
  font-family: "Noto Sans";
  src: url('../Assets/Fonts/NotoSans-Regular.ttf') format('truetype');
}
