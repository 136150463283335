.title {
  font-style: normal;
  font-weight: bold;
  color: #516176;

  &-22 {
    @extend .title;
    font-size: 22px;
  }

  &-17 {
    @extend .title;
    font-size: 17px;
  }
}

.type {
  font-family: Roboto;
  font-style: normal;
  line-height: 22px;
  color: #44566C;

  &-13 {
    @extend .type;
    font-size: 13px;
    &-300 {
      @extend .type-13;
      font-weight: 300;
    }

    &-bold {
      @extend .type-13;
      font-weight: bold;
    }
  }

  &-14 {
    @extend .type;
    font-weight: bold;
    font-size: 14px;
  }

  &-12 {
    @extend .type;
    font-size: 12px;

    &-300 {
      @extend .type-12;
      font-weight: 300;
    }

    &-bold {
      @extend .type-12;
      font-weight: bold;
    }

  }
}

.details {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;

  &-sub {
    font-weight: 300;
    font-size: 12px;
  }
}

.description {
  width: 30%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.actions {
  &-mobile {
    @media (max-width: 768px) {
      width: 80%;
    }
  }

  &-text {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    color: #383EB3;
  }
}
